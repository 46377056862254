import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { addCoupon } from "../../redux/apiCalls";
import "./newCoupon.css";

const NewCoupon = () => {
  const dispatch = useDispatch();
  const coupon = useSelector((state) => state.coupon);


  const [emptyFlieds, setEmptyFields] = useState(false)
  const [inputs, setInputs] = useState({
    title: "",
    couponCode: "",
    couponDiscount: "",
  });

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleClick = () => {
    if (
      inputs.title !== "" &&
      inputs.couponCode !== "" &&
      inputs.couponDiscount !== ""
    ) {
      addCoupon(inputs, dispatch);
        setEmptyFields(false)
    } else {
        setEmptyFields(true)
    }
  };

  return (
    <Countainer>
      <Wrapper>
        <Title>Add a new Coupon!</Title>
        <InputField>
          <Input
            onChange={handleChange}
            name="title"
            className=""
            type="title"
          />
          <Label inputVal={inputs.title} type="title">
            Title
          </Label>
        </InputField>
        <InputField>
          <Input
            onChange={handleChange}
            name="couponCode"
            className=""
            type="title"
          />
          <Label inputVal={inputs.couponCode} type="title">
            Coupon Code
          </Label>
        </InputField>
        <InputField>
          <Input
            onChange={handleChange}
            name="couponDiscount"
            className=""
            type="number"
          />
          <Label inputVal={inputs.couponDiscount} type="title">
            Discount Price xx.xx DA
          </Label>
        </InputField>
        <FlexToLeft>
        {emptyFlieds && <p style={{
            color: 'red'
        }}>Please fill all the inputs</p>}
        {coupon.success > 0 && <p style={{
            color: 'green'
        }}>Coupon added successfuly</p>}
        {coupon.isFetching && <p style={{
            color: 'black'
        }}>Please wait ....</p>}
        {coupon.error && <p style={{
            color: 'red'
        }}>An error happened</p>}
          <SubmitButtom onClick={handleClick} disabled={coupon.isFetching}>{coupon.isFetching ? "..." : "Submit"}</SubmitButtom>
        </FlexToLeft>
      </Wrapper>
    </Countainer>
  );
};
const SubmitButtom = styled.button`
  display: block;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  padding: 13px 30px;
  font-weight: 700;
  border: 2px solid #212a2f;
  border-radius: 5px;
  color: #fff;
  background-color: #212a2f;
  transition: color 0.25s ease-out, background-color 0.25s ease-out,
    border-color 0.25s ease-out;
  appearance: none;
  cursor: pointer;
  &:hover {
    background-color: #fff;
    color: #212a2f;
    font-weight: 700;
  }
`;

const FlexToLeft = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
`;

const Title = styled.h2`
  margin-bottom: 30px;
  text-align: center;
`;
const Wrapper = styled.div`
  width: 400px;
`;
const Countainer = styled.div`
  flex: 4;
  display: grid;
  place-content: center;
`;

const Input = styled.input`
  background-color: transparent;
  z-index: 999;
  margin: 0;
  appearance: none;
  background: transparent;
  border: 1px grey solid;
  background-clip: padding-box;
  border-radius: 5px;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 16px;
  width: 100%;
  padding: 0.8em 0.7857142857em;
  padding-top: 1.2em;
  word-break: normal;
  line-height: inherit;

  &:focus {
    outline: 1px solid black;
  }
`;

const Label = styled.label`
  pointer-events: none;
  position: absolute;
  left: ${(props) => (props.inputVal === "" ? "16px" : "8px")};
  top: ${(props) => (props.inputVal === "" ? "17px" : "4px")};
  font-size: ${(props) => (props.inputVal === "" ? "" : "13px")};
  /* left: 16px; */
  /* top: 17px; */
  color: #333;
  transition: all 0.1s;

  ${Input}:focus ~ & {
    top: 4px;
    font-size: 13px;
  }
`;

const InputField = styled.div`
  position: relative;
  margin-bottom: 30px;
`;

export default NewCoupon;
