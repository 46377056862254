import "./productList.css";
import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline, HighlightOff, Search } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { BASE_URL, publicRequest, userRequest } from "../../requestMethods";
import styled from "styled-components";
import Button from "@mui/material/Button";

export default function ProductList() {
  const [searchedProducts, setSearchedProducts] = useState([]);
  const [activeSearch, setActiveSearch] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [products, setProducts] = useState([]);

  const getProducts = async () => {
    try {
      const res = await publicRequest.get("/products");
      setProducts(res.data);
    } catch (err) {}
  };

  useEffect(() => {
    getProducts();
  }, [showConfirmation]);

  const getSearchedProducts = async (value) => {
    // dispatch start fetching
    try {
      const res = await publicRequest.get(`/products/search/${value}`);
      setSearchedProducts(res.data);
      // fetch success
    } catch (err) {
      // dispatch fetch err
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      setSearchedProducts([]);
      setActiveSearch(false);

      return;
    }
    getSearchedProducts(value);
    setActiveSearch(true);
  };

  const columns = [
    {
      field: "product",
      headerName: "Product",
      width: 300,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            <img
              className="productListImg"
              src={BASE_URL + "media/image/" + params.row.img[0]}
              alt=""
            />
            {params.row.title}
          </div>
        );
      },
    },
    { field: "ref", headerName: "reference", width: 220 },
    { field: "inStock", headerName: "Stock", width: 200 },
    {
      field: "price",
      headerName: "Price",
      width: 160,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/product/" + params.row._id}>
              <button className="productListEdit">Edit</button>
            </Link>
            <DeleteOutline
              className="productListDelete"
              onClick={() => {
                setShowConfirmation(true);
                setSelectedProduct(params.row);
                // handleDelete(params.row._id);
              }}
            />
          </>
        );
      },
    },
  ];

  return (
    <div className="productList">
      <SearchInputDesk>
        <Input
          placeholder="Search"
          onChange={handleSearchChange}
          // onBlur={() => setActiveSearch(false)}
          // onFocus={() => setActiveSearch(true)}
        />
        <Search
          style={{
            color: "black",
            fontSize: 20,
            position: "absolute",
            right: 10,
          }}
        />
      </SearchInputDesk>
      <DataGrid
        rows={activeSearch ? searchedProducts : products}
        disableSelectionOnClick
        columns={columns}
        getRowId={(row) => row._id}
        pageSize={20}
        checkboxSelection
      />
      {showConfirmation && (
        <VerifyDelete
          setShowConfirmation={setShowConfirmation}
          product={selectedProduct}
        />
      )}
    </div>
  );
}

const VerifyDelete = ({ product, setShowConfirmation }) => {
  const handleDeleteProduct = async () => {
    try {
      await userRequest.delete(`/products/${product._id}`);
      await userRequest.delete(`/media/images/`, {
        data: { images: product.img },
      });

      setShowConfirmation(false);
    } catch (err) {
      setShowConfirmation(true);
    }
  };
  return (
    <Container>
      <Card>
        <XIcon>
          <HighlightOff
            style={{
              fontSize: 50,
              color: "#c01f1f",
            }}
          />
        </XIcon>
        <BigTitle>Do You Want To Delete This Product </BigTitle>
        <Buttons>
          <Button
            variant="contained"
            color="error"
            style={{ marginRight: 30 }}
            onClick={handleDeleteProduct}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            onClick={() => setShowConfirmation(false)}
          >
            Cancel
          </Button>
        </Buttons>
      </Card>
    </Container>
  );
};

const Card = styled.div`
  max-height: 90vh;
  overflow: auto;
  min-width: 600px;
  max-width: 600px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(222, 224, 225, 0.715) 0px 8px 24px;
`;
const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* width: 100vw; */
  /* height: 100%; */
  display: grid;
  place-content: center;
  background-color: rgba(0, 0, 0, 0.375);
  backdrop-filter: blur(2px);
  overflow: hidden;
  z-index: 2;
`;

const XIcon = styled.div`
  padding-top: 20px;
  text-align: center;
`;
const BigTitle = styled.h1`
  text-align: center;
  padding: 15px;
  font-weight: 600;
  font-size: 30px;
`;
const Buttons = styled.div`
  text-align: center;
  padding-bottom: 20px;
`;


const Input = styled.input`
  flex: 1;
  min-width: 40%;
  background: #f5f5f5;
  /* margin: 20px 10px 0 0; */
  box-sizing: border-box;
  padding: 10px;
`;
const SearchInputDesk = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;
