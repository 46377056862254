import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { userRequest } from "../../requestMethods";
import { useDispatch, useSelector } from "react-redux";
import { updateDeliveryOptions } from "../../redux/apiCalls";
import { LocalShippingRounded } from "@material-ui/icons";

const Delivery = () => {
  const [inputList, setInputList] = useState([
    { wilaya: "", homePrice: "", relayPointPrice: "" },
  ]);

  const dispatch = useDispatch();
  const delivery = useSelector((state) => state.deliveryOptions);

  useEffect(() => {
    const getDeliveryOptions = async () => {
      try {
        const res = await userRequest.get("/delivery");
        setInputList(res.data.wilayas);
      } catch (err) {
        alert("err geting delivery options");
      }
    };
    getDeliveryOptions();
  }, []);

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };
  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      { wilaya: "", homePrice: "", relayPointPrice: "" },
    ]);
  };

  const handleClick = () => {
    updateDeliveryOptions({ wilayas: inputList }, dispatch);
  };

  return (
    <Container>
      <Wrapper>
        <h1>Delivery Options :</h1>

        <div className="variants-container">
          <div className="variant">
            <table>
              <tbody>
                <tr>
                  <th style={{ width: "300px" }}>Wilaya</th>
                  <th style={{ width: "200px" }}>Home Price</th>
                  <th style={{ width: "200px" }}>Rely Point Price</th>
                  <th>Delete</th>
                </tr>
                {inputList.map((x, i) => {
                  return (
                    <tr key={i}>
                      <th>
                        <input
                          name="wilaya"
                          value={x.wilaya}
                          onChange={(e) => handleInputChange(e, i)}
                          placeholder="wilaya"
                          className="form-control"
                          style={{ width: "300px" }}
                        />
                      </th>
                      <th>
                        <input
                          name="homePrice"
                          value={x.homePrice}
                          onChange={(e) => handleInputChange(e, i)}
                          placeholder="price"
                          className="form-control quantity"
                          style={{ width: "200px" }}
                          type="number"
                        />
                      </th>
                      <th>
                        <input
                          name="relayPointPrice"
                          value={x.relayPointPrice}
                          onChange={(e) => handleInputChange(e, i)}
                          placeholder="price"
                          className="form-control quantity"
                          style={{ width: "200px" }}
                          type="number"
                        />
                      </th>
                      <th className="btn-box">
                        {inputList.length !== 1 && (
                          <Button
                            variant="contained"
                            color="error"
                            type="button"
                            className="btn btn-remove"
                            onClick={() => handleRemoveClick(i)}
                          >
                            Remove
                          </Button>
                        )}
                      </th>
                      <th>
                        {inputList.length - 1 === i && (
                          <Button
                            variant="contained"
                            color="success"
                            className="btn"
                            type="button"
                            onClick={handleAddClick}
                          >
                            Add
                          </Button>
                        )}
                      </th>
                    </tr>
                  );
                })}
                {/* <div style={{ marginTop: 20 }}> */}
                {/* {JSON.stringify(inputList)} */}
                {/* </div> */}
              </tbody>
            </table>
          </div>
          <FlexToLeft>
            {delivery.success && (
              <p
                style={{
                  color: "green",
                }}
              >
                Delivery options edited successfuly
              </p>
            )}
            {delivery.isFetching && (
              <p
                style={{
                  color: "black",
                }}
              >
                Please wait ....
              </p>
            )}
            {delivery.error && (
              <p
                style={{
                  color: "red",
                }}
              >
                An error happened
              </p>
            )}
            <SubmitButtom onClick={handleClick} disabled={delivery.isFetching}>
              {delivery.isFetching ? "..." : "Submit"}
            </SubmitButtom>
          </FlexToLeft>
        </div>
      </Wrapper>
    </Container>
  );
};

const Wrapper = styled.div`
  display: grid;
  place-content: center;
  padding-right: 200px;
`;

const FlexToLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
`;

const SubmitButtom = styled.button`
  display: block;
  width: 200px;
  margin-bottom: 30px;
  margin-left: 300px;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  padding: 13px 30px;
  font-weight: 700;
  border: 2px solid #212a2f;
  border-radius: 5px;
  color: #fff;
  background-color: #212a2f;
  transition: color 0.25s ease-out, background-color 0.25s ease-out,
    border-color 0.25s ease-out;
  appearance: none;
  cursor: pointer;
  &:hover {
    background-color: #fff;
    color: #212a2f;
    font-weight: 700;
  }
`;

const Container = styled.div`
  flex: 4;
  /* padding-left: 40px; */
  h1 {
    margin-bottom: 30px;
    margin-top: 30px;
    font-weight: 600;
    text-decoration: underline;
  }
`;

export default Delivery;
