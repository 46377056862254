import axios from "axios"

// const url = "https://noah-api.onrender.com"
// const url = "http://localhost:5000"
const url = "https://api.noahstoredz.com"
// const url = "https://three-towns-judge-41-200-92-42.loca.lt"
export const BASE_URL = `${url}/api/`

// const TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzODBiYmI2MDA4OTYzYjQ5ODkxZjE5MiIsImlzQWRtaW4iOnRydWUsImlhdCI6MTY3NzYxNTAzOSwiZXhwIjoxNjc3ODc0MjM5fQ.HKXFdKqcJJxomZdSKdqJ3Fq6aHNBUgiBsOluaLe_q7g";
const isUser = localStorage.getItem('currentUser') !== null
const TOKEN = isUser ? JSON.parse(localStorage.getItem('currentUser')).accessToken : "" 
// const TOKEN = ""


export const publicRequest = axios.create({
  baseURL: BASE_URL,
});

export const userRequest = axios.create({
  baseURL: BASE_URL,
  headers: { token: `Bearer ${TOKEN}` },
});