import { ExitToApp, ExitToAppSharp, Language, NotificationsNone, Settings } from '@material-ui/icons'
import React from 'react'
import "./topbar.css"
import profilePic from "../../assets/profile-icon.jpg"
import { useDispatch } from 'react-redux'
import { resetUser } from '../../redux/userRedux'

export const Topbar = () => {
    const dispatch = useDispatch()
    const handleLogout = () => {
        dispatch(resetUser())
        console.log("logout");
    }
  return (
    <div className='topbar'>
        <div className="topbarWrapprt">
            <div className="topLeft">
                <span className="logo">noahadmin</span>
            </div>
            <div className="topRight">
                <div className="topbarIconsContainer">
                    <NotificationsNone/>
                    <span className='topIconBadge'>2</span>
                </div>
                <div className="topbarIconsContainer">
                    <Language/>
                </div>
                <div className="topbarIconsContainer">
                    <Settings/>
                </div>
                <div className="topbarIconsContainer logout" onClick={handleLogout}>
                    Log out<ExitToAppSharp/>
                </div>
                <img src={profilePic} alt="Top Avatar" className="topAvatar" />
            </div>
        </div>
    </div>
  )
}
