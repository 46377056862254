import {
  Text,
  SimpleGrid,
  ActionIcon,
  Image,
  Badge,
  Box,
  Skeleton,
} from "@mantine/core";
import { IconUpload, IconX } from "@tabler/icons-react";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { BASE_URL } from "../../requestMethods";

function DropZone({
  onAllUploaded,
  isUploading,
  setIsUploading,
  setMainImageIndex,
  mainImageIndex,
  files,
  setFiles

}) {
  // const [files, setFiles] = useState([]);
  // const [mainImage, setMainImage] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const inputRef = useRef(null);

  // const uploadMutation = useUploadImageMutation();

  const handleDropEvent = () => {
    if (!isUploading) setIsUploading(true);

    let selectedFiles = Array.from(inputRef.current?.files || []);

    selectedFiles = selectedFiles.filter(
      (file) => !files.map((f) => f.name).includes(file.name)
    );

    files.push(...selectedFiles);
    setFiles([...files]);
  };

  useEffect(() => {
    if (uploadedFiles.length === files.length) {
      setIsUploading(false);
      onAllUploaded(uploadedFiles);
      return;
    }
    if (!isUploading) setIsUploading(true);
  }, [uploadedFiles]);

  useEffect(() => {
    let allUploaded = true;
    for (let i = 0; i < files.length; ++i) {
      const file = files[i];
      const formData = new FormData();
      formData.append("images", file);
      if (!file.uploaded) {
        allUploaded = false;
        axios.post(BASE_URL + "media/images", formData).then((data) => {
          if (!!data?.data?.fileUrls?.length) {
            const updatedFiles = files;
            const imagePath = data?.data?.fileUrls[0];
            updatedFiles[i].uploaded = true;
            updatedFiles[i].url = imagePath;
            // updatedFiles[i].id = data.data.image.id;
            setFiles(updatedFiles);

            const uploadedFiles = updatedFiles.filter((v) => v.uploaded);
            setUploadedFiles(uploadedFiles);
          }
        });
      }
    }
    if (allUploaded) setIsUploading(false);
  }, [files]);

  const handleDeleteUploaded = async (url) => {
    try {
      await axios.delete(BASE_URL + `media/image/` + url);
      setUploadedFiles(uploadedFiles.filter((file) => file.url !== url));
      setMainImageIndex(0);
    } catch (error) {
      console.log(error);
    }
  };

  const previews = files.map((file, index) => {
    // const imageUrl = URL.createObjectURL(file);
    const imageUrl = BASE_URL + "media/image/" + file.url;
    return (
      <div
        style={{
          transition: "transform 500ms ease",
          position: "relative",
          // borderRadius: "1rem",
          cursor: "pointer",
        }}
        key={file.url}
      >
        {/* <LoadingOverlay
          sx={{
            width: "100%",
            height: "100%",
            position: "relative",
            backgroundColor: "transparent",
            borderRadius: "1rem",
          }}
          overlayOpacity={0}
          overlayBlur={file.uploaded ? 0 : 5}
          visible={!file.uploaded}
        /> */}

        {file.uploaded ? (
          <>
            <Image
              width={100}
              height={100}
              src={imageUrl}
              radius={"0.5rem"}
              onClick={() => {
                if (setMainImageIndex) {
                  setMainImageIndex(index);
                }
              }}
            />
            <ActionIcon
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                borderRadius: "1rem",
              }}
              color="red"
              onClick={() => {
                const filesList = files.filter((_, i) => i !== index);
                setFiles(filesList);
                handleDeleteUploaded(file.url);
              }}
            >
              <IconX />
            </ActionIcon>
            {index === mainImageIndex && (
              <Box
                sx={{
                  position: "absolute",
                  left: 5,
                  top: 5,
                }}
              >
                <Badge color="red">main</Badge>
              </Box>
            )}
          </>
        ) : (
          <Skeleton visible={!file.uploaded} width={100} height={100} />
        )}
      </div>
    );
  });

  return (
    <div style={{ cursor: !files.length ? "pointer" : "auto",  }}>
      <input
        type={"file"}
        style={{ display: "none" }}
        multiple
        onChange={handleDropEvent}
        ref={inputRef}
      />
      <div
        onClick={() => (!files.length ? inputRef.current?.click() : null)}
        style={{
          backgroundColor: "#61",
          borderRadius: "0.5rem",
          height: "auto",
          paddingBlock: "3rem",
          border: "dashed",
          display: "grid",
          placeItems: "center",
          borderColor: "gray",
        }}
      >
        {files.length ? (
          <SimpleGrid
            cols={4}
            breakpoints={[{ maxWidth: "sm", cols: 1 }]}
            mt={previews.length > 0 ? "xl" : 0}
          >
            {previews}
            <div
              style={{ cursor: "pointer" }}
              onClick={() => inputRef.current?.click()}
            >
              <div
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "0.5rem",
                  position: "relative",
                  display: "grid",
                  placeItems: "center",
                  border: "dashed",
                  borderColor: "gray",
                }}
              >
                <IconUpload size="3.2rem" stroke={1.5} />
              </div>
            </div>
          </SimpleGrid>
        ) : (
          <div style={{ display: "flex", gap: "1rem" }}>
            <IconUpload size="3.2rem" stroke={1.5} />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Text size="xl" inline>
                Drag images here or click to select files
              </Text>
              <Text size="sm" color="dimmed" inline mt={7}>
                Attach as many files as you like, each file should not exceed
                5mb
              </Text>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DropZone;
