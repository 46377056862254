import { Link, useLocation } from "react-router-dom";
import "./product.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL, userRequest } from "../../requestMethods";
import { updateProduct } from "../../redux/apiCalls";
import styled from "styled-components";
import "../coupon/coupon.css";
import Button from "@mui/material/Button";
import { Checkbox } from "@mui/material";

export default function Product() {
  const location = useLocation();
  const productId = location.pathname.split("/")[2];

  // const [pStats, setPStats] = useState([]);
  const [inputList, setInputList] = useState([{ size: "", quantity: "" }]);
  const [inputs, setInputs] = useState({});

  const [checked, setChecked] = useState(false);
  const [sold, setSold] = useState({});

  const [currentProduct, setCurrentProduct] = useState({});

  // const [product, setProduct]
  const product = useSelector((state) => state.product);

  const dispatch = useDispatch();

  const [emptyFlieds, setEmptyFields] = useState(false);

  // const product = useSelector((state) =>
  //   state.product.products.find((product) => product._id === productId)
  // );

  useEffect(() => {
    const getProduct = async () => {
      try {
        const res = await userRequest.get(`/products/find/${productId}`);
        setCurrentProduct(res.data);
        setInputs({
          title: res.data.title,
          ref: res.data.ref,
          desc: res.data.desc,
          price: res.data.price,
          sold: {
            soldPrice: res.data.sold.soldPrice,
            inSold: res.data.sold.inSold,
          },
          linkTo: res.data.linkTo,
        });
        setChecked(res.data.sold.inSold);
        setInputList(res.data.attr);
        setSold(res.data.sold);
      } catch (err) {}
    };
    getProduct();
  }, []);

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleCheckChange = (event) => {
    setChecked(event.target.checked);
    setSold((prev) => ({
      ...prev,
      inSold: event.target.checked,
    }));
  };
  const handleSoldPriceChange = (e) => {
    setSold((prev) => ({
      ...prev,
      soldPrice: e.target.value,
    }));
  };
  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };
  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { size: "", quantity: "" }]);
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    updateProduct(productId, inputs, dispatch);
  };

  const handleClick = () => {
    const payload = { ...inputs, sold, attr: inputList };
    updateProduct(productId, payload, dispatch);
    setEmptyFields(false);
  };
  return (
    <BigCountainer>
      <Container>
        <Left>
          <Wrapper>
            <Title>edit '{currentProduct.title}'</Title>
            <ImgContainer>
              <Img src={BASE_URL + "media/image/" + currentProduct?.img?.[0] || ''} />
            </ImgContainer>
            <InputField>
              <Input
                onChange={handleChange}
                name="title"
                className=""
                type="title"
                defaultValue={inputs.title}
              />
              <Label inputVal={inputs.title} type="title">
                Title
              </Label>
            </InputField>
            <InputField>
              <Input
                onChange={handleChange}
                name="ref"
                type="title"
                defaultValue={inputs.ref}
              />
              <Label inputVal={inputs.ref} type="title">
                Product Ref
              </Label>
            </InputField>
            <InputField>
              <Input
                onChange={handleChange}
                name="linkTo"
                type="title"
                defaultValue={inputs.linkTo}
              />
              <Label inputVal={inputs.linkTo} type="title">
                Product link To
              </Label>
            </InputField>
            <InputField>
              <Input
                onChange={handleChange}
                name="desc"
                type="text"
                defaultValue={inputs.desc}
              />
              <Label inputVal={inputs.desc} type="title">
                description
              </Label>
            </InputField>
            <InputField>
              <Input
                onChange={handleChange}
                name="price"
                type="text"
                defaultValue={inputs.price}
              />
              <Label inputVal={inputs.price} type="title">
                price
              </Label>
            </InputField>
            <InputField>
              <Input
                onChange={handleSoldPriceChange}
                name="sold"
                type="text"
                defaultValue={sold.soldPrice}
              />
              <Label type="title">sold price</Label>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p>in sold?</p>
                <Checkbox
                  checked={checked}
                  onChange={handleCheckChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
            </InputField>
            <FlexToLeft>
              {emptyFlieds && (
                <p
                  style={{
                    color: "red",
                  }}
                >
                  You've changed nothing
                </p>
              )}
              {product.success > 0 && (
                <p
                  style={{
                    color: "green",
                  }}
                >
                  Product edited successfuly
                </p>
              )}
              {product.isFetching && (
                <p
                  style={{
                    color: "black",
                  }}
                >
                  Please wait ....
                </p>
              )}
              {product.error && (
                <p
                  style={{
                    color: "red",
                  }}
                >
                  An error happened
                </p>
              )}
              <SubmitButtom onClick={handleClick} disabled={product.isFetching}>
                {product.isFetching ? "..." : "Submit"}
              </SubmitButtom>
            </FlexToLeft>
          </Wrapper>
        </Left>
        <Right>
          <div className="variants-container">
            <div className="variant">
              <table>
                <tbody>
                  <tr>
                    <th style={{ width: "120px" }}>Size</th>
                    <th style={{ width: "200px" }}>Quantity</th>
                    <th>Delete</th>
                  </tr>
                  {inputList.map((x, i) => {
                    return (
                      <tr key={i}>
                        <th>
                          <input
                            name="size"
                            value={x.size}
                            onChange={(e) => handleInputChange(e, i)}
                            placeholder="size"
                            className="form-control"
                            style={{ width: "100px" }}
                          />
                        </th>
                        <th>
                          <input
                            name="quantity"
                            value={x.quantity}
                            onChange={(e) => handleInputChange(e, i)}
                            placeholder="quantity"
                            className="form-control quantity"
                            style={{ width: "200px" }}
                            type="number"
                          />
                        </th>
                        <th>
                          <div className="btn-box">
                            {inputList.length !== 1 && (
                              <Button
                                variant="contained"
                                color="error"
                                type="button"
                                className="btn btn-remove"
                                onClick={() => handleRemoveClick(i)}
                              >
                                Remove
                              </Button>
                            )}
                          </div>
                        </th>
                        <th>
                          {inputList.length - 1 === i && (
                            <Button
                              variant="contained"
                              color="success"
                              className="btn"
                              type="button"
                              onClick={handleAddClick}
                            >
                              Add
                            </Button>
                          )}
                        </th>
                      </tr>
                    );
                  })}
                  {/* <div style={{ marginTop: 20 }}> */}
                  {/* {JSON.stringify(inputList)} */}
                  {/* </div> */}
                </tbody>
              </table>
            </div>
          </div>
        </Right>
      </Container>
    </BigCountainer>
  );
}

const Img = styled.img`
  width: 100%;
`;

const ImgContainer = styled.div`
  width: 200px;
  height: 200px;
  overflow: hidden;
  margin: 0 auto 20px;
  border-radius: 90px;
`;

const Left = styled.div`
  flex: 1;
`;
const Right = styled.div`
  flex: 1;
`;

const Container = styled.div`
  flex: 4;
  padding: 20px;
  display: flex;
  flex-direction: row;
`;
const SubmitButtom = styled.button`
  display: block;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  padding: 13px 30px;
  font-weight: 700;
  border: 2px solid #212a2f;
  border-radius: 5px;
  color: #fff;
  background-color: #212a2f;
  transition: color 0.25s ease-out, background-color 0.25s ease-out,
    border-color 0.25s ease-out;
  appearance: none;
  cursor: pointer;
  &:hover {
    background-color: #fff;
    color: #212a2f;
    font-weight: 700;
  }
`;

const FlexToLeft = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
`;

const Title = styled.h2`
  margin-bottom: 30px;
  text-align: center;
`;
const Wrapper = styled.div`
  width: 400px;
  margin-right: 50px;
`;
const BigCountainer = styled.div`
  flex: 4;
  display: grid;
  place-content: center;
`;

const Input = styled.input`
  background-color: transparent;
  z-index: 999;
  margin: 0;
  appearance: none;
  background: transparent;
  border: 1px grey solid;
  background-clip: padding-box;
  border-radius: 5px;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 16px;
  width: 100%;
  padding: 0.8em 0.7857142857em;
  padding-top: 1.2em;
  word-break: normal;
  line-height: inherit;

  &:focus {
    outline: 1px solid black;
  }
`;

const Label = styled.label`
  pointer-events: none;
  position: absolute;
  left: ${(props) => (props.inputVal === "" ? "16px" : "8px")};
  top: ${(props) => (props.inputVal === "" ? "17px" : "4px")};
  font-size: ${(props) => (props.inputVal === "" ? "" : "13px")};
  /* left: 16px; */
  /* top: 17px; */
  color: #333;
  transition: all 0.1s;

  ${Input}:focus ~ & {
    top: 4px;
    font-size: 13px;
  }
`;

const InputField = styled.div`
  position: relative;
  margin-bottom: 30px;
`;
