import Chart from "../../components/chart/Chart";
import { Featuredinfo } from "../../components/featuredinfo/Featuredinfo";
import "./home.css";
import { WidgetLg } from "../../components/WidgetLg/WidgetLg";
import { useEffect, useMemo, useState } from "react";
import { userRequest } from "../../requestMethods";

export const Home = () => {
  const [orderStats, setOrderStats] = useState([]);
  const [orderChange, setOrderChange] = useState(false)

  const MONTHS = useMemo(
    () => [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Agu",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    []
  );

  useEffect(() => {
    const getStats = async () => {
      try {
        const res = await userRequest.get("/orders/stats");
        res.data.map((item,) =>
          setOrderStats((prev) => [
            ...prev,
            {  name: MONTHS[item._id - 1], "Orders Post": item.total },
          ])
        );
      } catch (err) {}
    };
    getStats()
  }, [MONTHS]);

  return (
    <div className="home">
      <Featuredinfo orderChange={orderChange}/>
      <div className="homeWidget">
        {/* <WidgetSm /> */}
        <WidgetLg setOrderChange={setOrderChange}/>
      </div>
      <Chart
        data={orderStats}
        title="Orders Analytics"
        grid
        dataKey="Orders Post"
      />
    </div>
  );
};
