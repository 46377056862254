import styled from "styled-components";
import {
  Close,
  HomeRounded,
  ApartmentRounded,
  DeleteForeverRounded,
  HighlightOff,
} from "@material-ui/icons";
import Button from "@mui/material/Button";

import "./orderPopup.css";
import { useState } from "react";
import { BASE_URL, userRequest } from "../../requestMethods";

export const OrderPopup = ({ order, setSelectedOreder }) => {
  const [orderstatus, setOrderStatus] = useState(order.status);
  const [openOptions, setOpenOptions] = useState(false);
  const [confirmation, setConfirmation] = useState(false);

  //update Order Status function
  const handleChangeStatus = async (status) => {
    try {
      const res = userRequest.put(`/orders/${order._id}`, {
        status,
      });
      setOrderStatus(status);
      setOpenOptions(false);
    } catch (err) {}
  };

  return (
    <Container>
      {/* {console.log(order)} */}

      <Card>
        {/*//? header */}
        <Section>
          <Header>
            <OrderInfo>
              <OrderId>{`Order #${order._id}`}</OrderId>
              <div style={{ position: "relative" }}>
                <FlexRow>
                  <OrderStatus
                    className={orderstatus}
                    onClick={() => setOpenOptions((prev) => !prev)}
                  >
                    <Circle className={`${orderstatus} circle`} />
                    {orderstatus}
                  </OrderStatus>
                  <DeleteOrder onClick={() => setConfirmation(true)}>
                    <DeleteForeverRounded
                      style={{
                        color: "#c01f1f",
                        fontSize: 27,
                      }}
                    />
                  </DeleteOrder>
                </FlexRow>
                {openOptions && (
                  <Options>
                    <Option
                      selected={orderstatus === "approved"}
                      onClick={() => handleChangeStatus("approved")}
                    >
                      <OrderStatus className="approved">
                        <Circle className={`approved circle`} />
                        approved
                      </OrderStatus>
                    </Option>
                    <Option
                      selected={orderstatus === "declined"}
                      onClick={() => handleChangeStatus("declined")}
                    >
                      <OrderStatus className="declined">
                        <Circle className={`declined circle`} />
                        declined
                      </OrderStatus>
                    </Option>
                    <Option
                      selected={orderstatus === "delivered"}
                      onClick={() => handleChangeStatus("delivered")}
                    >
                      <OrderStatus className="delivered">
                        <Circle className={`delivered circle`} />
                        delivered
                      </OrderStatus>
                    </Option>
                    <Option
                      selected={orderstatus === "pending"}
                      onClick={() => handleChangeStatus("pending")}
                    >
                      <OrderStatus className="pending">
                        <Circle className={`pending circle`} />
                        pending
                      </OrderStatus>
                    </Option>
                    <Option
                      selected={orderstatus === "returned"}
                      onClick={() => handleChangeStatus("returned")}
                    >
                      <OrderStatus className="returned">
                        <Circle className={`returned circle`} />
                        returned
                      </OrderStatus>
                    </Option>
                  </Options>
                )}
              </div>
            </OrderInfo>
            <CloseCard onClick={() => setSelectedOreder(null)}>
              <Close style={{ fontSize: 30 }} />
            </CloseCard>
          </Header>
          <OrderDate>
            Order date:&nbsp;&nbsp;{" "}
            <p>{new Date(order.createdAt).toLocaleString()}</p>
          </OrderDate>
        </Section>
        {/*//?products */}
        <Section>
          <Products>
            {order.products?.map((product, index) => (
              <Product key={index}>
                <ProductImg>
                  <ProductQuantity>{product.quantity}</ProductQuantity>
                  <Img src={BASE_URL + "media/image/" +product.img} />
                </ProductImg>
                <Info>
                  <ProductTitle>{product.title}</ProductTitle>
                  <ProductSize>ref : {product.ref}</ProductSize>
                  <ProductSize>size : {product.size}</ProductSize>
                </Info>
                <ProductPrice>
                  DA {product.price} <p>Qty: {product.quantity}</p>
                </ProductPrice>
              </Product>
            ))}
          </Products>
        </Section>
        {/* //*grid */}
        <Section>
          <TwoRow>
            {/* //?delivery */}
            <Half>
              <HalfTitle>Delivery</HalfTitle>
              <HalfContent>
                <SmallTitle>Cient</SmallTitle>
                <Text>
                  {order.firstname} {order.lastname}
                </Text>
                <Br />
                <SmallTitle>Contact</SmallTitle>
                <Text>{order.userphone}</Text>
                <Text>{order.email}</Text>
                <Br />
                <SmallTitle>Address</SmallTitle>
                <Text>{order.useraddress}</Text>
                <Text>
                  {order.wilaya} - {order.usertown}
                </Text>
              </HalfContent>
            </Half>
            {/* //?order Info */}
            <Half>
              <HalfTitle>Order Info</HalfTitle>
              <HalfContent>
                <SmallTitle>Delivery info</SmallTitle>
                <Text>
                  {order.delivery === "homePrice" ? (
                    <>
                      Home delivery <HomeRounded style={{ color: "#2b2b2b" }} />
                    </>
                  ) : (
                    <>
                      Yalidine relay point
                      <ApartmentRounded style={{ color: "#2b2b2b" }} />
                    </>
                  )}
                </Text>
                <Br />
                <SmallTitle>Coupon</SmallTitle>
                <Text>
                  {order.couponInfo.couponCode === ""
                    ? "No coupon used!"
                    : order.couponInfo.couponCode}
                </Text>
              </HalfContent>
            </Half>
          </TwoRow>
        </Section>
        <Section>
          <TwoRow>
            {/* //?user comment */}
            <Half>
              <HalfTitle>User comment</HalfTitle>
              <HalfContent>
                <h4>
                  {order.addComment === ""
                    ? "User add no comment"
                    : order.addComment}
                </h4>
              </HalfContent>
            </Half>

            {/* //?order summary */}
            <Half>
              <HalfTitle>Order Summary</HalfTitle>
              <Summary>
                <SummaryItem>
                  <Text>Subtotal</Text>
                  <Text>DA {order.subTotal}</Text>
                </SummaryItem>
                <SummaryItem>
                  <SmallTitle>Discount</SmallTitle>
                  <SmallTitle>
                    ({order.couponInfo.couponDiscountPercentag}%) - DA{" "}
                    {order.couponInfo.couponDiscountPrice}
                  </SmallTitle>
                </SummaryItem>
                <SummaryItem>
                  <SmallTitle>Delivery</SmallTitle>
                  <SmallTitle>+ DA {order.deliveryPrice}</SmallTitle>
                </SummaryItem>
                <Dashed />
                <SummaryItem>
                  <TotalText>Total</TotalText>
                  <TotalText>DA {order.total}</TotalText>
                </SummaryItem>
              </Summary>
            </Half>
          </TwoRow>
        </Section>
      </Card>

      {confirmation && (
        <VerifyDelete
          setConfirmation={setConfirmation}
          setSelectedOreder={setSelectedOreder}
          id={order._id}
        />
      )}
    </Container>
  );
};

const VerifyDelete = ({ id, setConfirmation, setSelectedOreder }) => {
  const handleDeleteOrder = async () => {
    try {
      const res = userRequest.delete(`/orders/${id}`);
      // console.log(res)
      setSelectedOreder(false);
      setConfirmation(false);
    } catch (err) {
      setConfirmation(true);
      console.log(err);
    }
  };
  return (
    <Container>
      <Card>
        <XIcon>
          <HighlightOff
            style={{
              fontSize: 50,
              color: "#c01f1f",
            }}
          />
        </XIcon>
        <BigTitle>Do You Want To Delete This Order </BigTitle>
        <Buttons>
          <Button
            variant="contained"
            color="error"
            style={{ marginRight: 30 }}
            onClick={handleDeleteOrder}
          >
            Delete
          </Button>
          <Button variant="contained" onClick={() => setConfirmation(false)}>
            Cancel
          </Button>
        </Buttons>
      </Card>
    </Container>
  );
};

const XIcon = styled.div`
  padding-top: 20px;
  text-align: center;
`;
const BigTitle = styled.h1`
  text-align: center;
  padding: 15px;
  font-weight: 600;
  font-size: 30px;
`;
const Buttons = styled.div`
  text-align: center;
  padding-bottom: 20px;
`;

const DeleteOrder = styled.div`
  cursor: pointer;
`;

const Option = styled.div`
  display: ${(props) => (props.selected ? "none" : null)};
`;

const Options = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: right;
  top: 40px;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #cfbbbb;
  border-radius: 10px;
`;

const Circle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 5px;
`;

const TotalText = styled.h4`
  font-size: 20px;
`;

const Summary = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  gap: 7px;
`;

const Dashed = styled.hr`
  width: 1px;
  color: #7c7c7c6a;
  width: 100%;
  border-style: dashed;
`;

const SummaryItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Br = styled.div`
  height: 15px;
`;

const SmallTitle = styled.p`
  color: #00000091;
`;
const Text = styled.p`
  font-size: 19px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const HalfTitle = styled.p`
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 15px;
`;
const HalfContent = styled.div`
  h4 {
    line-height: 23px;
  }
`;

const Half = styled.div`
  flex: 1;
`;

const TwoRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 20px;
  gap: 30px;
`;

const Img = styled.img`
  width: 100%;
  object-fit: contain;
  box-sizing: border-box;
  border: 1px solid grey;
  border-radius: 9px;
`;
const ProductQuantity = styled.div`
  position: absolute;
  top: -8px;
  left: -8px;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  background-color: #d2dee0;
  color: black;
  display: grid;
  place-content: center;
`;

const Products = styled.div`
  /* max-height: 200px; */
  /* overflow-y: auto; */
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const Product = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  color: #323232;
  margin-bottom: 7px;
`;

const ProductImg = styled.div`
  width: 90px;
  /* height: 4.6em; */
  border-radius: 8px;
  background: transparent;
  margin-right: 10px;
  position: relative;
`;
const ProductPrice = styled.div`
  width: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  font-size: 17px;
  gap: 5px;
  p {
    font-weight: 300;
  }
`;
const Info = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
`;
const ProductTitle = styled.div`
  font-size: 19px;
  line-height: 1.2;
  letter-spacing: 1px;
`;
const ProductSize = styled.div`
  font-size: 0.9em;
  font-weight: 500;
  color: #202020;
  text-transform: uppercase;
`;

const OrderDate = styled.div`
  padding: 10px 20px;
  display: flex;
  p {
    font-weight: 600;
  }
`;

const Section = styled.div`
  border-bottom: 1px solid #7c7c7c6a;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 20px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  letter-spacing: 1px;
  padding: 20px 20px 10px;
`;
const OrderInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
`;
const OrderId = styled.div`
  font-weight: 600;
  font-size: 18px;
`;
const OrderStatus = styled.div`
  position: relative;
  padding: 5px 7px;
  border: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
`;
const CloseCard = styled.p`
  cursor: pointer;
`;

const Card = styled.div`
  max-height: 90vh;
  overflow: auto;
  min-width: 600px;
  max-width: 600px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(222, 224, 225, 0.715) 0px 8px 24px;
`;
const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* width: 100vw; */
  /* height: 100%; */
  display: grid;
  place-content: center;
  background-color: rgba(0, 0, 0, 0.375);
  backdrop-filter: blur(2px);
  overflow: hidden;
  z-index: 2;
`;
