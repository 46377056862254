import React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteCoupon, getCoupons } from "../../redux/apiCalls";
import "../prodctList/productList.css";

const CouponList = () => {
  const dispatch = useDispatch();
  const coupons = useSelector((state) => state.coupon.coupons);

  useEffect(() => {
    getCoupons(dispatch);
  }, [dispatch]);

  const handleDelete = (id) => {
    deleteCoupon(id, dispatch);
  };

  const columns = [
    { field: "title", headerName: "title", width: 220 },
    {
      field: "coupon",
      headerName: "Coupon",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            {params.row.couponCode}
          </div>
        );
      },
    },
    { field: "inService", headerName: "service", width: 200 },
    {
      field: "couponDiscount",
      headerName: "Price",
      width: 160,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/coupon/" + params.row._id}>
              <button className="productListEdit">Edit</button>
            </Link>
            <DeleteOutline
              className="productListDelete"
              onClick={() => handleDelete(params.row._id)}
            />
          </>
        );
      },
    },
  ];
  return (
    <div className="productList">
      <DataGrid
        rows={coupons}
        disableSelectionOnClick
        columns={columns}
        getRowId={(row) => row._id}
        pageSize={20}
        checkboxSelection
      />
    </div>
  );
};

export default CouponList;
