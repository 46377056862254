import { configureStore } from "@reduxjs/toolkit"
import userReducer from "./userRedux"
import productReducer from "./productRedux"
import couponRedux from "./couponRedux"
import deliveryRedux from "./deliveryOptionsRedux"

export default configureStore({
    reducer: {
        product: productReducer,
        user: userReducer,
        coupon: couponRedux,
        deliveryOptions: deliveryRedux
    },
})
