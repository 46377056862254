// Import the functions you need from the SDKs you need
import { initializeApp, getApps, getApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import {getFirestore} from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA_xCc9kEd0WJs2OPcgbj9paxHod0bTmNg",
  authDomain: "noah-shop-ad163.firebaseapp.com",
  projectId: "noah-shop-ad163",
  storageBucket: "noah-shop-ad163.appspot.com",
  messagingSenderId: "333907120609",
  appId: "1:333907120609:web:6a0cb4a8d06ed5ff1fc2a0",
  measurementId: "G-F71G75RS47"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore()
const storage = getStorage()
export {app, db, storage}