import React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { useEffect } from "react";
import "../prodctList/productList.css";
import { useState } from "react";
import { userRequest } from "../../requestMethods";
import { Visibility } from "@material-ui/icons";
import { OrderPopup } from "../../components/Orderpopup/OrderPopup";
import "./orderList.css"

const OrderList = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOreder] = useState();

  useEffect(() => {
    const getORders = async () => {
      try {
        const res = await userRequest.get("/orders");
        setOrders(res.data);
      } catch (err) {
      }
    };
    getORders();
  }, [selectedOrder]);

  const selectOrder = (order) => {
    setSelectedOreder(order);
  };

  const columns = [
    {
        field: "status",
        headerName: "status",
        width: 120,
        renderCell: (params) => {
          return <button onClick={() => selectOrder(params.row)} className={"orderListButton " + params.row.status}>{params.row.status}</button>;
        },
      },
    {
      field: "Client",
      headerName: "Client",
      width: 180,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            {params.row.firstname} {params.row.lastname}
          </div>
        );
      },
    },
    {
      field: "phone",
      headerName: "phone",
      width: 150,
      renderCell: (params) => {
        return <div className="productListItem">{params.row.userphone}</div>;
      },
    },
    {
      field: "wilaya",
      headerName: "wilaya",
      width: 120,
      renderCell: (params) => {
        return <div className="productListItem">{params.row.wilaya}</div>;
      },
    },
    {
      field: "total",
      headerName: "total",
      width: 160,
      renderCell: (params) => {
        return <div className="productListItem">{params.row.total} DA</div>;
      },
    },

    
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <button
              className="widgetViewButton"
              onClick={() => selectOrder(params.row)}
            >
              <Visibility className="widgetViewIcon" />
              Display
            </button>
          </>
        );
      },
    },
  ];
  return (
    <div className="productList">
      <DataGrid
        rows={orders}
        disableSelectionOnClick
        columns={columns}
        getRowId={(row) => row._id}
        pageSize={20}
        checkboxSelection
      />
     {selectedOrder && <OrderPopup order={selectedOrder} setSelectedOreder={setSelectedOreder}></OrderPopup>}

    </div>
  );
};

export default OrderList;
