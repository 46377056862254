import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import { useEffect } from "react";
import { useState } from "react";
import { userRequest } from "../../requestMethods";
import "./featuredinfo.css";
export const Featuredinfo = ({ orderChange }) => {
  const [income, setIncome] = useState({});
  const [perc, setPerc] = useState(0);

  useEffect(() => {
    const getIncome = async () => {
      try {
        const res = await userRequest.get("/orders/income");
        const data = res.data;
        setIncome(data);
        const currentIncom = data.currentMonth?.total || 0;
        const prevIncome = data.prevMonth?.total;
        setPerc((currentIncom * 100) / prevIncome - 100);
        // console.log(data)
        console.log({ currentIncom, prevIncome });
        console.log((prevIncome * 100) / currentIncom - 100);
      } catch (err) {}
    };
    getIncome();
  }, [orderChange]);
  return (
    <div className="featured">
      <div className="featuredItem">
        <span className="featuredTitle">Revenue</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">
            {/* DA{" "} */}
            {Intl.NumberFormat(undefined, {
              style: "currency",
              currency: "DZD",
            }).format(income.currentMonth?.total)}
          </span>
          <span className="featuredMoneyRate">
            {Math.floor(perc)}
            {"%"}
            {perc < 0 ? (
              <ArrowDownward className="featuredIcon negative" />
            ) : (
              <ArrowUpward className="featuredIcon" />
            )}
          </span>
        </div>
        <span className="featuredSub">Compared to last month</span>
      </div>
      <div className="featuredItem">
        {/* <span className="featuredTitle">Sales</span> */}
        <div className="featuredMoneyContainer">
          {/* <span className="featuredMoney">DA4,454</span> */}
          <span className="featuredMoneyRate">
            {/* -1.4 <ArrowDownward className="featuredIcon negative" />{" "} */}
          </span>
        </div>
        {/* <span className="featuredSub">Compared to last month</span> */}
      </div>
      <div className="featuredItem">
        {/* <span className="featuredTitle">Cost</span> */}
        <div className="featuredMoneyContainer">
          {/* <span className="featuredMoney">DA2,023</span> */}
          {/* <span className="featuredMoneyRate">
            +2.1
            <ArrowUpward className="featuredIcon" />
          </span> */}
        </div>
        {/* <span className="featuredSub">Compared to last month</span> */}
      </div>
    </div>
  );
};
