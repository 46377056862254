import { useEffect, useState } from "react";
import { userRequest } from "../../requestMethods";
import { format } from "timeago.js";
import { Visibility } from "@material-ui/icons";

import "./WidgetLg.css";
import { OrderPopup } from "../Orderpopup/OrderPopup";

export const WidgetLg = ({setOrderChange}) => {
  const [orders, setOrders] = useState([]);

  const [selectedOrder, setSelectedOreder] = useState();

  useEffect(() => {
    const getORders = async () => {
      try {
        const res = await userRequest.get("/orders", {
          params: {
            limit: 10,
          },
        });
        setOrders(res.data);
      } catch (err) {
      }
    };
    getORders();
    setOrderChange(prev => !prev)
  }, [selectedOrder]);

  const selectOrder = (order) => {
    setSelectedOreder(order);
  };

  const Button = ({ type }) => {
    return <button className={"widgetLgButton " + type}>{type}</button>;
  };
  return (
    <div className="WidgetLg">
      <h3 className="widgetLgTitle">Latest transcations</h3>
      <table className="widgetLgTable">
        <tbody>
          <tr className="widgetLgTr">
            <th className="widgetLgTh">Customer</th>
            <th className="widgetLgTh">Date</th>
            <th className="widgetLgTh">Wilaya</th>
            <th className="widgetLgTh">Status</th>
            <th className="widgetLgTh">View</th>
          </tr>
          {orders.map((order, index) => (
            <tr className="widgetLgTr" key={index}>
              <td className="widgetLgUser">
                {/* <img src="https://images.pexels.com/photos/4172933/pexels-photo-4172933.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" alt="" className="widgetLgImg" /> */}
                <span className="widgetLgName">
                  {order.firstname + " " + order.lastname}
                </span>
              </td>
              <td className="widgetLgDate">{format(order.createdAt)}</td>
              <td className="widgetLgAmount"> {order.wilaya}</td>
              <td className="widgetLgStatus">
                <Button type={order.status} />
              </td>
              <td>
                <button
                  className="widgetViewButton"
                  onClick={() => selectOrder(order)}
                >
                  <Visibility className="widgetViewIcon" />
                  Display
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {selectedOrder && (
        <OrderPopup
          order={selectedOrder}
          setSelectedOreder={setSelectedOreder}
        ></OrderPopup>
      )}
    </div>
  );
};
