import { useState } from "react";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
// import "./newProduct.css";
import "./newProduct1.css";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { app } from "../../../firebase";
import { addProduct } from "../../../redux/apiCalls";

//GENDER
import React from "react";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import Dropzone, { useDropzone } from "react-dropzone";
//category
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import Input from "@mui/material/Input";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";

// import SendIcon from '@mui/icons-material/Send';
import { Send, Replay } from "@material-ui/icons";
import styled from "styled-components";
import ImageUploader from "../../../components/upload/ImageUploader";
import DropZone from "../../../components/upload/DropZone";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const categories = ["shoes", "clothes", "accessories"];
const sousCategories = [
  "pants",
  "outerwear",
  "polo",
  "tshirt",
  "sweatshirt",
  "pantacourt",
  "shoes",
  "sandals",
  "flip-flops",
  "cap",
  "backpack",
];
const marks = [
  "nike",
  "blueva",
  "jordan",
  "adidas",
  "under-armour",
  "puma",
  "champion",
  "new balance",
  "umbro",
  "le coq sportif",
  "reebok",
  "sketchers",
  "converse",
  "vans",
  "caterpillar",
  "hush puppies",
  "sebago",
  "clarks",
  "crocs",
  "hummel",
  "geox",
  "harmont blaine",
  "havaianas",
  "asics",
  "pro touch",
];
const seasons = ["all", "winter", "summer", "spring", "autumn"];
function getStyles(name, cat, theme) {
  return {
    fontWeight:
      cat.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function NewProduct() {
  const [inputs, setInputs] = useState({ gender: "male", img: [] });
  const [file, setFile] = useState(null);
  const [inputList, setInputList] = useState([{ size: "", quantity: "" }]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [colorsFiltered, setColorsFiltered] = useState([]);

  function choosenColor(color) {
    if (colorsFiltered.includes(color)) {
      const colors = colorsFiltered.filter((item) => item !== color);
      setColorsFiltered(colors);
    } else {
      setColorsFiltered((prev) => [...prev, color]);
    }
  }

  const [value, setValue] = useState("men");

  const handleGenderChange = (event) => {
    setValue(event.target.value);
    setInputs((prev) => {
      return { ...prev, gender: event.target.value };
    });
  };

  //category ui
  const theme = useTheme();
  const [cat, setCat] = useState([]);
  const [souscat, setsousCat] = useState([]);
  const [mark, setMark] = useState([]);
  const [season, setSeason] = useState([]);

  const handleCatChange = (event) => {
    const {
      target: { value },
    } = event;
    setCat(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleSousCatChange = (event) => {
    const {
      target: { value },
    } = event;
    setsousCat(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleMarkChange = (event) => {
    const {
      target: { value },
    } = event;
    setMark(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleSeasonChange = (event) => {
    const {
      target: { value },
    } = event;
    setSeason(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  //handle Sold
  const [checked, setChecked] = useState(false);
  const [sold, setSold] = useState({});

  const handleCheckChange = (event) => {
    setChecked(event.target.checked);
    setSold((prev) => ({ ...prev, inSold: !checked }));
  };
  const handleSoldPriceChange = (event) => {
    setSold((prev) => ({ ...prev, soldPrice: event.target.value }));
  };

  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  // const handleCat = (e) => {
  //   setCat(e.target.value.split(","));
  // };

  // const uploadMainImg =()

  // console.log(inputs, cat, file);

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { size: "", quantity: "" }]);
  };

  const [images, setImages] = useState([]);
  const [files, setFiles] = useState([]);

  const [mainImageIndex, setMainImageIndex] = useState(0);

  const handleAllUploaded = (files) => {
    setImages(files);
  };

  const [isImagesUploading, setIsImagesUploading] = useState(false);

  function reorderArray(array, id) {
    if (id >= 0 && id < array.length) {
      let item = array[id];
      array = array.filter((_, index) => index !== id); // remove the item from its current position
      array.unshift(item); // add the item to the beginning of the array
    }
    return array;
  }
  const handleClick = async (e) => {
    e.preventDefault();
    // handleMainClick();
    if (images.length) {
      const imagesSortedWithMain = reorderArray(images, mainImageIndex).map(
        (e) => e.url
      );
      addProduct(
        {
          ...inputs,
          img: imagesSortedWithMain,
          cat: cat,
          souscat: souscat,
          mark: mark,
          attr: inputList,
          sold: sold,
          gender: value,
          color: colorsFiltered,
          season: season,
        },
        dispatch
      )
        .then(() => {
          setFiles([]);
          setInputs({ gender: "male", img: [] });
          setCat([]);
          setsousCat([]);
          setMark([]);
          setInputList([{ size: "", quantity: "" }]);
          setSold({});
          setValue("men");
          setColorsFiltered([]);
          setSeason([]);
          console.log("upload all images done");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <form className="addProductForm" id="form">
      <div className="newProduct">
        <div className="from-title">Product information:</div>
        <hr style={{ marginBottom: "20px" }} />
        <div className="wrapper">
          <div className="left">
            <div className="form-container">
              <div className="labels-inputs">
                <div className="addProductItem">
                  <InputLabel>Name: *</InputLabel>
                  <input
                    onChange={handleChange}
                    name="title"
                    type="text"
                    placeholder="Nike air force 1"
                    required
                  ></input>
                </div>
                <div className="addProductItem">
                  <InputLabel>Ref: *</InputLabel>
                  <input
                    onChange={handleChange}
                    name="ref"
                    type="text"
                    placeholder="XXX-xxx"
                    required
                  ></input>
                </div>

                <div className="addProductItem">
                  <InputLabel>Link To: *</InputLabel>
                  <input
                    onChange={handleChange}
                    name="linkTo"
                    type="text"
                    placeholder="XXX-xxx"
                    required
                  ></input>
                </div>
                <GridCenter>
                  <InputLabel>Color: *</InputLabel>
                  <Sizes>
                    <Color onClick={() => choosenColor("purple")}>
                      <ColorCircle color="#8D429F">
                        {colorsFiltered.includes("purple") && <CheckMark />}
                      </ColorCircle>
                      <ColorName>Purple</ColorName>
                    </Color>
                    <Color onClick={() => choosenColor("black")}>
                      <ColorCircle color="#111">
                        {colorsFiltered.includes("black") && <CheckMark />}
                      </ColorCircle>
                      <ColorName>Black</ColorName>
                    </Color>
                    <Color onClick={() => choosenColor("red")}>
                      <ColorCircle color="#E7352B">
                        {colorsFiltered.includes("red") && <CheckMark />}
                      </ColorCircle>
                      <ColorName>Red</ColorName>
                    </Color>
                    <Color onClick={() => choosenColor("orange")}>
                      <ColorCircle color="#F36B26">
                        {colorsFiltered.includes("orange") && <CheckMark />}
                      </ColorCircle>
                      <ColorName>Orange</ColorName>
                    </Color>
                    <Color onClick={() => choosenColor("blue")}>
                      <ColorCircle color="#1790C8">
                        {colorsFiltered.includes("blue") && <CheckMark />}
                      </ColorCircle>
                      <ColorName>Blue</ColorName>
                    </Color>
                    <Color onClick={() => choosenColor("white")}>
                      <ColorCircle color="#fff">
                        {colorsFiltered.includes("white") && <DarkCheckMark />}
                      </ColorCircle>
                      <ColorName>White</ColorName>
                    </Color>
                    <Color onClick={() => choosenColor("brown")}>
                      <ColorCircle color="#825D41">
                        {colorsFiltered.includes("brown") && <CheckMark />}
                      </ColorCircle>
                      <ColorName>Brown</ColorName>
                    </Color>
                    <Color onClick={() => choosenColor("green")}>
                      <ColorCircle color="#7BBA3C">
                        {colorsFiltered.includes("green") && <CheckMark />}
                      </ColorCircle>
                      <ColorName>Green</ColorName>
                    </Color>
                    <Color onClick={() => choosenColor("mix")}>
                      <ColorCircle color="">
                        {colorsFiltered.includes("mix") && <DarkCheckMark />}
                      </ColorCircle>
                      <ColorName>Multi-Color</ColorName>
                    </Color>
                    <Color onClick={() => choosenColor("grey")}>
                      <ColorCircle color="#808080">
                        {colorsFiltered.includes("grey") && <CheckMark />}
                      </ColorCircle>
                      <ColorName>Grey</ColorName>
                    </Color>
                    <Color onClick={() => choosenColor("pink")}>
                      <ColorCircle color="#F0728F">
                        {colorsFiltered.includes("pink") && <CheckMark />}
                      </ColorCircle>
                      <ColorName>Pink</ColorName>
                    </Color>
                    <Color onClick={() => choosenColor("yellow")}>
                      <ColorCircle color="#ffee00">
                        {colorsFiltered.includes("yellow") && <CheckMark />}
                      </ColorCircle>
                      <ColorName>Yellow</ColorName>
                    </Color>
                  </Sizes>
                </GridCenter>
                <div className="addProductItem">
                  <InputLabel>description: *</InputLabel>
                  <textarea
                    onChange={handleChange}
                    name="desc"
                    type="text"
                    placeholder="The Esqido Companion Eyelash Glue is the ..."
                    required
                  ></textarea>
                </div>
                {/* //categorie */}

                {/* <div className="addProductItem">
                  <label>Categories</label>
                  <input
                    onChange={handleCat}
                    type="text"
                    placeholder="shoes,...."
                  />
                </div> */}

                <div className="variants-container ">
                  <div className="variant">
                    <table>
                      <tbody>
                        <tr>
                          <th style={{ width: "120px" }}>Size</th>
                          <th style={{ width: "200px" }}>Quantity</th>
                          <th>Delete</th>
                        </tr>
                        {inputList.map((x, i) => {
                          return (
                            <tr key={i}>
                              <th>
                                <input
                                  name="size"
                                  value={x.size}
                                  onChange={(e) => handleInputChange(e, i)}
                                  placeholder="size"
                                  className="form-control"
                                  style={{ width: "100px" }}
                                />
                              </th>
                              <th>
                                <input
                                  name="quantity"
                                  value={x.quantity}
                                  onChange={(e) => handleInputChange(e, i)}
                                  placeholder="quantity"
                                  className="form-control quantity"
                                  style={{ width: "200px" }}
                                />
                              </th>
                              <th className="btn-box">
                                {/* <div className="btn-box"> */}
                                {inputList.length !== 1 && (
                                  <Button
                                    variant="contained"
                                    color="error"
                                    type="button"
                                    className="btn btn-remove"
                                    onClick={() => handleRemoveClick(i)}
                                  >
                                    Remove
                                  </Button>
                                )}
                                {/* </div> */}
                              </th>
                              <th>
                                {inputList.length - 1 === i && (
                                  <Button
                                    variant="contained"
                                    color="success"
                                    className="btn"
                                    type="button"
                                    onClick={handleAddClick}
                                  >
                                    Add
                                  </Button>
                                )}
                              </th>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            <div className="addProductItem">
              <InputLabel>Price: *</InputLabel>
              <input
                onChange={handleChange}
                name="price"
                type="number"
                placeholder="XXX-xxx"
                required
                min="0"
              ></input>
            </div>
            <div className="addProductItem">
              <InputLabel>Sold Price:</InputLabel>
              <div className="soldInput">
                <input
                  className="input"
                  onChange={handleSoldPriceChange}
                  name="soldPrice"
                  type="number"
                  placeholder="9999"
                  min="0"
                ></input>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p>in sold?</p>
                  <Checkbox
                    checked={checked}
                    onChange={handleCheckChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>
              </div>
            </div>

            <div>
              <div className="addProductItem">
                <FormControl sx={{ m: 1, width: 300 }}>
                  <InputLabel id="demo-multiple-chip-label">
                    Categories:
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={cat}
                    onChange={handleCatChange}
                    input={
                      <OutlinedInput id="select-multiple-chip" label="Chip" />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {categories.map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        style={getStyles(name, cat, theme)}
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="addProductItem">
                <FormControl sx={{ m: 1, width: 300 }}>
                  <InputLabel id="demo-multiple-chip-label">
                    Sous Categories:
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={souscat}
                    onChange={handleSousCatChange}
                    input={
                      <OutlinedInput id="select-multiple-chip" label="Chip" />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {sousCategories.map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        style={getStyles(name, souscat, theme)}
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="addProductItem">
                <FormControl sx={{ m: 1, width: 300 }}>
                  <InputLabel id="demo-multiple-chip-label">Mark:</InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={mark}
                    onChange={handleMarkChange}
                    input={
                      <OutlinedInput id="select-multiple-chip" label="Chip" />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {marks.map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        style={getStyles(name, mark, theme)}
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="addProductItem">
                <FormControl sx={{ m: 1, width: 300 }}>
                  <InputLabel id="demo-multiple-chip-label">Season:</InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={season}
                    onChange={handleSeasonChange}
                    input={
                      <OutlinedInput id="select-multiple-chip" label="Chip" />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {seasons.map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        style={getStyles(name, season, theme)}
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <Box sx={{ maxWidth: 120 }} className="addProductItem">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Gender*</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    label="Value"
                    onChange={handleGenderChange}
                  >
                    <MenuItem value="men">Men</MenuItem>
                    <MenuItem value="women">Women</MenuItem>
                    <MenuItem value="unisex">Unisex</MenuItem>
                    <MenuItem value="kids">Kids</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <div className="form-container" style={{ marginBottom: 50 }}>
                <DropZone
                  onAllUploaded={handleAllUploaded}
                  setIsUploading={setIsImagesUploading}
                  isUploading={isImagesUploading}
                  setMainImageIndex={setMainImageIndex}
                  mainImageIndex={mainImageIndex}
                  files={files}
                  setFiles={setFiles}
                />
              </div>
              <div>
                <LoadingButton
                  size="large"
                  onClick={handleClick}
                  endIcon={<Send />}
                  loading={isLoading}
                  loadingPosition="end"
                  variant="contained"
                  disabled={isImagesUploading || isLoading}
                >
                  Send
                </LoadingButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
const DarkCheckMark = () => {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      style={{
        enableBackground: "new 0 0 24 24",
      }}
      xmlSpace="preserve"
    >
      <style type="text/css">{"\n\t.st0{fill:#FFFFFF;}\n"}</style>
      <path
        className="st1"
        d="M20.3,2L9,13.6l-5.3-5L0,12.3L9,21L24,5.7L20.3,2z"
      />
    </svg>
  );
};

const CheckMark = () => {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      style={{
        enableBackground: "new 0 0 24 24",
      }}
      xmlSpace="preserve"
    >
      <style type="text/css">{"\n\t.st0{fill:#FFFFFF;}\n"}</style>
      <path
        className="st0"
        d="M20.3,2L9,13.6l-5.3-5L0,12.3L9,21L24,5.7L20.3,2z"
      />
    </svg>
  );
};

const ColorName = styled.p`
  padding-top: 2px;
  font-size: 14px;
`;
const Color = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

const ColorCircle = styled.div`
  width: 28px;
  height: 28px;
  text-align: center;
  border-radius: 14px;
  border: 1px solid #1111;
  box-sizing: border-box;
  padding: 5px;
  background-color: ${(props) => props.color};
`;

const GridCenter = styled.div`
  width: 250px;
  display: grid;
  place-content: left;
`;
const Sizes = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 7px;
  /* width: 280px; */
  margin-bottom: 30px;
  margin-top: 20px;
  padding: 2px;
  border: 1px solid
    ${(props) => (props.clickable === "red" ? "#631212" : "transparent")};
`;

{
  /* <div className="newProduct">
      <h1 className="addProductTitle">New Product</h1>
      <form className="addProductForm">
        <div className="addProductItem">
          <label>Image</label>
          <input
            type="file" 
            id="file"
            onChange={(e) => setFile(e.target.files[0])}
          />
        </div>
        <div className="addProductItem">
          <label>Title</label>
          <input
            onChange={handleChange}
            name="title"
            type="text"
            placeholder="Nike air force 1"
          />
        </div>
        <div className="addProductItem">
          <label>Ref</label>
          <input disabled name="ref" type="text" placeholder="db2477-100" />
        </div>
        <div className="addProductItem">
          <label>Description</label>
          <input
            onChange={handleChange}
            name="desc"
            type="text"
            placeholder="The Nike Air Force 1 remains a popular retro shoe..."
          />
        </div>
        <div className="addProductItem">
          <label>Price</label>
          <input
            onChange={handleChange}
            name="price"
            type="number"
            placeholder="19800"
          />
        </div>
        <div className="addProductItem">
          <label>Stock</label>
          <input disabled type="number" placeholder="12" />
        </div>
        
        <div className="addProductItem">
          <label>Active</label>
          <label>In Stock</label>
          <select name="inStock" id="inStock" onChange={handleChange}>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </div>
        <button onClick={handleClick} className="addProductButton" >
          Create
        </button>
      </form>
    </div> */
}
