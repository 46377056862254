import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/apiCalls";
import LOGO from "../../assets/LOGO.png";
import styled from "styled-components";
import "../newCoupon/newCoupon.css";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const user = useSelector(state => state.user)

  const handleClick = (e) => {
    e.preventDefault();
    login(dispatch, { username, password });
  };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Logo>
        <img src={LOGO} alt="logo" />
      </Logo>
      <InputField>
        <Input
          onChange={(e) => setUsername(e.target.value)}
          name="username"
          className=""
          type="username"
        />
        <Label inputVal={username} type="username">
          username
        </Label>
      </InputField>
      <InputField>
        <Input
          onChange={(e) => setPassword(e.target.value)}
          name="password"
          className=""
          type="password"
        />
        <Label inputVal={password} type="password">
          password
        </Label>
      </InputField>
      {/* <input
        style={{ padding: 10, marginBottom: 20 }}
        type="text"
        placeholder="username"
        onChange={(e) => setUsername(e.target.value)}
      />
      <input
        style={{ padding: 10, marginBottom: 20 }}
        type="password"
        placeholder="password"
        onChange={(e) => setPassword(e.target.value)}
      /> */}
      
      {user.success > 0 && (
        <p
          style={{
            color: "green",
          }}
        >
          Coupon added successfuly
        </p>
      )}
      {user.isFetching && (
        <p
          style={{
            color: "black",
          }}
        >
          Please wait ....
        </p>
      )}
      {user.loginFailure && (
        <p
          style={{
            color: "red",
          }}
        >
          An error happened
        </p>
      )}
      <SubmitButtom onClick={handleClick} disabled={user.isFetching}>
        {user.isFetching ? "..." : "Submit"}
      </SubmitButtom>
      {/* <button onClick={handleClick} style={{ padding: 10, width: 100 }}>
        Login
      </button> */}
    </div>
  );
};

const SubmitButtom = styled.button`
  display: block;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  padding: 13px 30px;
  font-weight: 700;
  border: 2px solid #212a2f;
  border-radius: 5px;
  color: #fff;
  background-color: #212a2f;
  transition: color 0.25s ease-out, background-color 0.25s ease-out,
    border-color 0.25s ease-out;
  appearance: none;
  cursor: pointer;
  &:hover {
    background-color: #fff;
    color: #212a2f;
    font-weight: 700;
  }
`;

const Input = styled.input`
  background-color: transparent;
  z-index: 999;
  margin: 0;
  appearance: none;
  background: transparent;
  border: 1px grey solid;
  background-clip: padding-box;
  border-radius: 5px;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 16px;
  width: 100%;
  padding: 0.8em 0.7857142857em;
  padding-top: 1.2em;
  word-break: normal;
  line-height: inherit;

  &:focus {
    outline: 1px solid black;
  }
`;

const Label = styled.label`
  pointer-events: none;
  position: absolute;
  left: ${(props) => (props.inputVal === "" ? "16px" : "8px")};
  top: ${(props) => (props.inputVal === "" ? "17px" : "4px")};
  font-size: ${(props) => (props.inputVal === "" ? "" : "13px")};
  /* left: 16px; */
  /* top: 17px; */
  color: #333;
  transition: all 0.1s;

  ${Input}:focus ~ & {
    top: 4px;
    font-size: 13px;
  }
`;

const InputField = styled.div`
  position: relative;
  margin-bottom: 30px;
`;

const Logo = styled.div`
  width: 150px;
  padding: 20px;
  img {
    width: 100%;
  }
`;

export default Login;
