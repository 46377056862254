import { loginFailure, loginStart, loginSuccess } from "./userRedux";
import { publicRequest, userRequest } from "../requestMethods";
import {
  getProductFailure,
  getProductStart,
  getProductSuccess,
  deleteProductFailure,
  deleteProductStart,
  deleteProductSuccess,
  updateProductFailure,
  updateProductStart,
  updateProductSuccess,
  addProductFailure,
  addProductStart,
  addProductSuccess,
} from "./productRedux";
import { addCouponFailure, addCouponStart, addCouponSuccess, deleteCouponFailure, deleteCouponStart, deleteCouponSuccess, getCouponFailure, getCouponStart, getCouponSuccess, updateCouponFailure, updateCouponStart, updateCouponSuccess } from "./couponRedux";
import { updateDeliveryOptionsFailure, updateDeliveryOptionsStart, updateDeliveryOptionsSuccess } from "./deliveryOptionsRedux";

export const login = async (dispatch, user) => {
  dispatch(loginStart());
  try {
    const res = await publicRequest.post("/auth/login", user);
    const duration = 2.16e+8 // + 2.5 days =>  ms
    const expiredDate = Date.now() + duration
    localStorage.setItem("currentUser", JSON.stringify({ ...res.data, expiredDate }))
    dispatch(loginSuccess(res.data));
  } catch (err) {
    dispatch(loginFailure());
  }
};

export const getProducts = async (dispatch) => {
  dispatch(getProductStart());
  try {
    const res = await publicRequest.get("/products");
    dispatch(getProductSuccess(res.data));
  } catch (err) {
    dispatch(getProductFailure());
  }
};

export const deleteProduct = async (id, dispatch) => {
  dispatch(deleteProductStart());
  try {
    const res = await userRequest.delete(`/products/${id}`);
    dispatch(deleteProductSuccess(id));
  } catch (err) {
    dispatch(deleteProductFailure());
  }
};

export const updateProduct = async (id, product, dispatch) => {
  dispatch(updateProductStart());
  try {
    const res = await userRequest.put(`/products/${id}`, product)
    dispatch(updateProductSuccess({ id, product }));
    return res
  } catch (err) {
    dispatch(updateProductFailure());
  }
};

export const addProduct = async (product, dispatch) => {
  dispatch(addProductStart());
  try {
    const res = await userRequest.post(`/products`, product);
    dispatch(addProductSuccess(res.data));
    return res
  } catch (err) {
    dispatch(addProductFailure());
    throw err
  }
};

//* ============= COUPONS ===========
export const getCoupons = async (dispatch) => {
  dispatch(getCouponStart());
  try {
    const res = await userRequest.get("/coupons");
    dispatch(getCouponSuccess(res.data));
  } catch (err) {
    dispatch(getCouponFailure());
  }
};

export const deleteCoupon = async (id, dispatch) => {
  dispatch(deleteCouponStart());
  try {
    const res = await userRequest.delete(`/coupons/${id}`);
    dispatch(deleteCouponSuccess(id));
  } catch (err) {
    dispatch(deleteCouponFailure());
  }
};

export const updateCoupon = async (id, coupon, dispatch) => {
  dispatch(updateCouponStart());
  try {
    const res = await userRequest.put(`/coupons/${id}`, coupon)
    dispatch(updateCouponSuccess({ id, coupon }));
  } catch (err) {
    dispatch(updateCouponFailure());
  }
};

export const addCoupon = async (coupon, dispatch) => {
  dispatch(addCouponStart());
  try {
    const res = await userRequest.post(`/coupons`, coupon);
    dispatch(addCouponSuccess(res.data));
  } catch (err) {
    dispatch(addCouponFailure());
  }
};

//* ============= Delivery ==========
export const updateDeliveryOptions = async (payload, dispatch) => {
  dispatch(updateDeliveryOptionsStart());
  try {
    const res = await userRequest.put(`/delivery`, payload)
    dispatch(updateDeliveryOptionsSuccess());
  } catch (err) {
    dispatch(updateDeliveryOptionsFailure());
  }
};