import { Button } from "@mantine/core";
import React, { useState } from "react";
import styled from "styled-components";

const ImageUploader = () => {
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);

  const handleFileChange = (event) => {
    setFile(event.target.files);
  };

  const uploadFile = () => {
    const formData = new FormData();
    formData.append("images", file);

    const xhr = new XMLHttpRequest();
    xhr.open("POST", "http://localhost:5000/api/media/images", true);

    xhr.upload.onprogress = (event) => {
      const progress = (event.loaded / event.total) * 100;
      console.log(progress);
      setProgress(progress);
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        console.log("File uploaded successfully!");
        console.log("Server response:", xhr.responseText); // Get the server response here
        setProgress(0);
      } else {
        console.error("Error uploading file.");
      }
    };

    xhr.send(formData);
  };
  const selected_main_images = () => {
    let imageUrl = file ? URL.createObjectURL(file) : "";
    return (
      <div className="">
        <img src={imageUrl} className="main-img" alt="" />
      </div>
    );
  };

  return (
    <div>
      <h1>File Upload</h1>
      <div id="progress-bar"></div>
      <div className="addProductItem">
        <label className="input-preview">
          <input
            className={"input-preview__src " + (file && ".has-image")}
            name="img"
            id="img"
            type="file"
            // accept=".png, .jpg, .jpeg .webp"
            onChange={(e) => setFile(e.target.files[0])}
          />
          {selected_main_images()}
        </label>
        <ProgressBarContainer>
          <Progress style={{ width: `${progress}%` }}></Progress>
        </ProgressBarContainer>
        <Button variant="contained" onClick={uploadFile}>
          Upload
        </Button>
      </div>
    </div>
  );
};

const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
`;

const Progress = styled.div`
  width: 0;
  height: 20px;
  background-color: #4caf50;
  border-radius: 5px;
`;

export default ImageUploader;
