import { createSlice } from "@reduxjs/toolkit";

export const couponSlice = createSlice({
    name: "coupon",
    initialState: {
        coupons: [],
        isFetching: false,
        error: false,
        success: 0,
    },
    reducers: {
        //GET ALL
        getCouponStart: (state) => {
            state.isFetching = true;
            state.error = false;

        },
        getCouponSuccess: (state, action) => {
            state.isFetching = false;
            state.coupons = action.payload;
        },
        getCouponFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        //DELETE
        deleteCouponStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        deleteCouponSuccess: (state, action) => {
            state.isFetching = false;
            state.coupons.splice(
                state.coupons.findIndex((item) => item._id === action.payload),
                1
            );
        },
        deleteCouponFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //UPDATE
        updateCouponStart: (state) => {
            state.isFetching = true;
            state.error = false;
            state.success = 0;
        },
        updateCouponSuccess: (state, action) => {
            state.isFetching = false;
            state.coupons[
                state.coupons.findIndex((item) => item._id === action.payload.id)
            ] = action.payload.coupon;
            state.success = 1;

        },
        updateCouponFailure: (state) => {
            state.isFetching = false;
            state.error = true;
            state.success = 0;

        },
        //UPDATE
        addCouponStart: (state) => {
            state.isFetching = true;
            state.error = false;
            state.success = 0;

        },
        addCouponSuccess: (state, action) => {
            state.isFetching = false;
            state.coupons.push(action.payload);
            state.success = 1;

        },
        addCouponFailure: (state) => {
            state.isFetching = false;
            state.success = 0;
            state.error = true;
        },
    },
});

export const {
    getCouponStart,
    getCouponSuccess,
    getCouponFailure,
    deleteCouponStart,
    deleteCouponSuccess,
    deleteCouponFailure,
    updateCouponStart,
    updateCouponSuccess,
    updateCouponFailure,
    addCouponStart,
    addCouponSuccess,
    addCouponFailure,
} = couponSlice.actions;

export default couponSlice.reducer;
