import {
  AttachMoney,
  BarChartOutlined,
  ChatBubbleOutline,
  DynamicFeed,
  LineStyle,
  MailOutline,
  PermIdentity,
  Report,
  Storefront,
  Timeline,
  TrendingUp,
  WorkOutline,
  MoneyOff,
  LocalShipping,
  AssignmentOutlined
} from "@material-ui/icons";
import React from "react";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./sidebar.css";

export const Sidebar = () => {

  const location = useLocation()
  const pathname = location.pathname.split('/')[1]
  console.log(pathname)

  const isActive = (item,item2) => {
    return (pathname === item || pathname === item2) ? "active" : ""
  }
  // useEffect(()=>{

  // },[location])
  // sidebarListItem
  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Dashboard</h3>
          <ul className="sidebarList">
            <Link to="/" className="link">
              <li className={`sidebarListItem ${isActive("")}`}>
                <LineStyle className="sidebarIcon" />
                Home
              </li>
            </Link>
            <li className={`sidebarListItem ${isActive(null)}`}>
              <Timeline className="sidebarIcon" />
              {/* Analytics */}
            </li>
            <Link to="/orders" className="link">
              <li className={`sidebarListItem ${isActive("/orders")}`}>
                <AssignmentOutlined className="sidebarIcon" />
                Orders
              </li>
            </Link>
            <li className={`sidebarListItem ${isActive(null)}`}>
              <TrendingUp className="sidebarIcon" />
              {/* Sales */}
            </li>
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Quick Menu</h3>
          <ul className="sidebarList">
            <Link to="/users" className="link">
              <li className={`sidebarListItem ${isActive("/users")}`}>
                <PermIdentity className="sidebarIcon" />
                {/* Users */}
              </li>
            </Link>
            <Link to="/products" className="link">
              <li className={`sidebarListItem ${isActive("products", "product")}`}>
                <Storefront className="sidebarIcon" />
                Products
              </li>
            </Link>
            <Link to="/newproduct" className="link">
              <li className={`sidebarListItem ${isActive("newproduct")}`}>
                <Storefront className="sidebarIcon" />
                new Product
              </li>
            </Link>
            <Link to="/coupons" className="link">
              <li className={`sidebarListItem ${isActive("coupons", "coupon")}`}>
                <MoneyOff className="sidebarIcon" />
                Coupons
              </li>
            </Link>
            <Link to="/newcoupon" className="link">
              <li className={`sidebarListItem ${isActive("newcoupon")}`}>
                <MoneyOff className="sidebarIcon" />
                new Coupon
              </li>
            </Link>
            <Link to="/delivery" className="link">
              <li className={`sidebarListItem ${isActive("delivery")}`}>
                <LocalShipping className="sidebarIcon" />
                Delivery
              </li>
            </Link>
            <li className={`sidebarListItem ${isActive(null)}`}>
              <AttachMoney className="sidebarIcon" />
              {/* Transactions */}
            </li>
            <li className={`sidebarListItem ${isActive(null)}`}>
              <BarChartOutlined className="sidebarIcon" />
              {/* Reports */}
            </li>
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Notifications</h3>
          <ul className="sidebarList">
            <li className="sidebarListItem">
              <MailOutline className="sidebarIcon" />
              {/* Mail */}
            </li>
            <li className="sidebarListItem">
              <DynamicFeed className="sidebarIcon" />
              {/* Feedback */}
            </li>
            <li className="sidebarListItem">
              <ChatBubbleOutline className="sidebarIcon" />
              {/* Messages */}
            </li>
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Staff</h3>
          <ul className="sidebarList">
            <li className="sidebarListItem">
              <WorkOutline className="sidebarIcon" />
              {/* Manage */}
            </li>
            <li className="sidebarListItem">
              <Timeline className="sidebarIcon" />
              {/* Analytics */}
            </li>
            <li className="sidebarListItem">
              <Report className="sidebarIcon" />
              {/* Reports */}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
