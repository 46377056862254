import { createSlice } from "@reduxjs/toolkit"

export const deliveryOptionsSlice = createSlice({
    name: "deliveryOptions",
    initialState: {
        isFetching: false,
        error: false,
        success: false
    },
    reducers: {
        updateDeliveryOptionsStart: (state) => {
            state.isFetching = true;
            state.error = false;
            state.success = false
        },
        updateDeliveryOptionsSuccess: (state) => {
            state.isFetching = false;
            state.success = true
        },
        updateDeliveryOptionsFailure: (state) => {
            state.isFetching = false;
            state.error = true;
            state.success = false
        },
    }
})
export const {
    updateDeliveryOptionsFailure, updateDeliveryOptionsStart, updateDeliveryOptionsSuccess
} = deliveryOptionsSlice.actions

export default deliveryOptionsSlice.reducer